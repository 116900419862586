import React from "react";
import { Grid, Typography } from "@mui/material";

function Careermain() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} marginTop={{ md: 5, lg: 18, sm: 3 }}>
          <Typography
            sx={{
              fontSize: "40px",
              fontFamily: "Roboto",
              lineHeight: "45px",
              fontWeight: 600,
              maxWidth: "606px",
              letterSpacing: "-2.5%",
              color: "#000958",
              marginTop: "20px",
            }}
          >
            Careers at Pets Help Squad
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontFamily: "Roboto",
              lineHeight: "45px",
              fontWeight: 600,
              letterSpacing: "-2.5%",
              color: "#000958",
              marginTop: "15px",
            }}
          >
            Join Our Team
          </Typography>
          <p
            style={{
              margin: "0",
              fontSize: "18px",
              fontFamily: "Roboto",
              lineHeight: "28px",
              maxWidth: "700px",
              color: "#656689",
              fontWeight: 500,
            }}
          >
            At Pets Help Squad, we’re passionate about providing top-quality
            care for pets across the UK. Our mission is to create a community of
            dedicated professionals who share our commitment to enhancing the
            lives of pets and their owners. If you’re enthusiastic,
            compassionate, and ready to make a difference, we’d love to hear
            from you!
          </p>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src="../../assets/careers.png"
            alt="oweners"
            style={{ maxWidth: "100%" }}
          />
        </Grid>
      </Grid>

      {/* <Grid sx={{ display: "flex", alignItems: "center" }} container xs={12}>
      <Grid
        item
        lg={6}
        md={12}
        xs={12}
        xl={6}
        sm={12}
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: "30px",
              fontFamily: "Roboto",
              lineHeight: "45px",
              fontWeight: 600,
              maxWidth: "606px",
              letterSpacing: "-2.5%",
              color: "#000958",
              marginTop: "20px",
            }}
          >
            Careers at Pets Help Squad
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontFamily: "Roboto",
              lineHeight: "45px",
              fontWeight: 600,
              maxWidth: "606px",
              letterSpacing: "-2.5%",
              color: "#000958",
              marginTop: "15px",
            }}
          >
            Join Our Team
          </Typography>

          <p
            style={{
              margin: "0",
              fontSize: "18px",
              fontFamily: "Roboto",
              lineHeight: "28px",
              maxWidth: "570px",
              color: "#111827",
              fontWeight: 400,
            }}
          >
            At Pets Help Squad, we’re passionate about providing top-quality
            care for pets across the UK. Our mission is to create a community of
            dedicated professionals who share our commitment to enhancing the
            lives of pets and their owners. If you’re enthusiastic,
            compassionate, and ready to make a difference, we’d love to hear
            from you!
          </p>
        </Box>
      </Grid>
      <Grid item lg={6} md={6} xs={12} xl={6} sm={8}>
        <img
          src="../../assets/careers.png"
          alt="oweners"
          style={{ maxWidth: "100%" }}
        ></img>
      </Grid>
    </Grid> */}
    </>
  );
}

export default Careermain;
