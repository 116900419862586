import { Box, Typography } from "@mui/material";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function TermsAndConditions() {
  return (
    <React.Fragment>
      <Box
        sx={{
          px: { xs: 2, sm: 5, md: 25, lg: 30, xl: 40 },
          paddingBottom: 5,
        }}
      >
        <Box sx={{ textAlign: "center", marginY: "60px" }}>
          <Typography
            style={{
              fontSize: "40px",
              fontFamily: "Roboto",
              lineHeight: "50px",
              fontWeight: 600,
              color: "#000958",
            }}
          >
            Terms & Conditions
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          Introduction
        </Typography>
        <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
          Welcome to petshelpsquad.co.uk is operated by Pay io Services LTD ("We").
          We are registered in England and Wales under company number 15439659
          with its registered office at International House, 36-38 Cornhill, London, England, EC3V
          3NG, United Kingdom. These terms and conditions outline the rules and
          regulations for the use of our website and services. By accessing or
          using our website, you agree to comply with these terms.
        </Typography>
        <br />
        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          1. Definitions
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> "We", "us", "our" :{" "}
          {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            Refers to Pets Help Squad is operated by Pay io Services LTD.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> "You", "user" :{" "}
          {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            Refers to any individual accessing our website or using our
            services.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> "Service Providers"
          : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            Refers to groomers, pet sitters, vet doctors, and sellers registered
            on our platform.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> "Services" : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            Refers to all services offered through Pets Help Squad is operated
            by Pay io Services LTD, including grooming, pet sitting, veterinary
            services, and product sales.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          2. Registration and Accounts
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Eligibility : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            To use our services, you must be at least 18 years old.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Account Creation :{" "}
          {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            You must provide accurate and complete information when creating an
            account. You are responsible for maintaining the confidentiality of
            your account details.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Account Usage :{" "}
          {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            You must notify us immediately of any unauthorised use of your
            account. We are not liable for any loss or damage arising from your
            failure to protect your account information.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          3. Services
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Grooming Services :{" "}
          {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            Groomers provide services at the pet owner's location. Booking can
            be made after viewing the groomer's profile and appointment history.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Pet Sitting
          Services: : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            Pet sitters offer day care, walking, boarding, drop-in visits, and
            house sitting services as per available appointment slots.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Veterinary Services
          : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            Vet doctors provide at-home, clinic, or video consultancy services,
            including nutrition, microchipping, vaccinations, and more.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Product Sales :{" "}
          {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            Sellers offer various pet products for purchase on our platform.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          4. Bookings and Payments
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Bookings : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            All bookings must be made through our website. You agree to provide
            accurate information when making a booking.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Payments : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            Payments for services and products must be made through our secure
            payment system. We accept major credit and debit cards
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Cancellation and
          Refunds: : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            Cancellation and refund policies vary depending on the service
            provider. Please review the specific terms of the service provider
            before making a booking.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          5. User Responsibilities
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Accurate
          Information : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            You must provide accurate, complete, and current information when
            using our services.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Compliance with
          Laws : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            You agree to comply with all applicable laws and regulations when
            using our website and services.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Respectful Conduct
          : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            You must treat all service providers and other users with respect
            and courtesy.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          6. Service Provider Responsibilities
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Professional
          Conduct : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            Service providers must deliver services in a professional and
            courteous manner.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Compliance with
          Laws: : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            Service providers must comply with all applicable laws and
            regulations, including those related to animal welfare.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Insurance : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            Service providers are responsible for maintaining appropriate
            insurance coverage.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          7. Intellectual Property
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Ownership : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            All content on our website, including text, graphics, logos, and
            images, is the property of Pets Help Squad or our licensors.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Use of Content :{" "}
          {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            You may not reproduce, distribute, or use any content from our
            website without our prior written permission.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          8. Limitation of Liability
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> No Warranties: :{" "}
          {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            We provide our services on an "as is" and "as available" basis. We
            do not warrant that our services will be uninterrupted or
            error-free.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Limitation of
          Liability: {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            To the fullest extent permitted by law, we are not liable for any
            indirect, incidental, or consequential damages arising from your use
            of our services.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          9. Termination
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Termination by You
          : {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            You may terminate your account at any time by contacting us.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Termination by Us :{" "}
          {""}
          <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
            We may suspend or terminate your account if you violate these terms
            or engage in any conduct that we deem harmful to our business or
            users.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          10. Changes to Terms
        </Typography>

        <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
          We may update these terms and conditions from time to time. Any
          changes will be posted on this page, and your continued use of our
          website and services constitutes acceptance of the new terms.
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          11. Governing Law
        </Typography>

        <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
          These terms and conditions are governed by and construed in accordance
          with the laws of England and Wales. Any disputes arising from these
          terms will be subject to the exclusive jurisdiction of the courts of
          England and Wales.
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          Contact Us
        </Typography>

        <Typography variant="p" sx={{ fontSize: "18px", color: "#111827", fontWeight:400 }}>
          If you have any questions about these terms and conditions, please
          contact us at Info@petshelpsquad.co.uk.
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default TermsAndConditions;
