import { Container } from "@mui/material";
import React from "react";
import WhatWeDo from "../components/WhatWeDo";
import CustomerTrust from "../components/CustomerTrust";
import ConnectingPets from "../components/ConnectingPets";

export const HomePage = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "100px",
      }}
    >
      <ConnectingPets />
      <WhatWeDo />

      <CustomerTrust />
    </Container>
  );
};
