import React from "react";
import PrivacyPolicy from "../components/privacy-policy";

export default function PrivacyPolicyPage() {
  return (
    <React.Fragment>
      <PrivacyPolicy />
    </React.Fragment>
  );
}
