import { Box, Divider, Grid, Container, Typography, Link } from "@mui/material";
// import { ReactComponent as SMDLogo } from "../../assets/svg/logo/smd-logo.svg";
// import { ReactComponent as SMDTextLogo } from "../../assets/svg/logo/Group.svg";
import { FooterLinks } from "./footer-links/footer-links";
import {
  servicesData,
  // Supporting,
  // helpCenterData,
  // socialIcons,
  SiteLinks,
} from "./footer-links/footer-links-data";

export const AppFooter = () => {
  return (
    <>
      <Box className="dog-footer">
        <img
          src="../icons/dog-footer.png"
          alt="facebook"
          style={{ maxWidth: "90%" }}
        ></img>
      </Box>
      <div style={{ bgcolor: "rgba(255, 250, 245, 1)", maxWidth: "100%" }}>
        <Container
          maxWidth={false}
          sx={{
            maxWidth: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={12}>
                <Grid item lg={6} md={6} xs={12} xl={6} sm={6}>
                  <div>
                    <img src="../assets/homepageImages/logo.svg" alt="logo" />
                  </div>
                  <div
                    style={{ fontSize: "15px", color: "#1C3E6C" }}
                    className="margin-left-1"
                  >
                    Reliable Pet Care Services
                  </div>
                  <div className=" margin-top-1 margin-left-1">
                    <Typography
                      sx={{
                        color: "#1C3E6C",
                        fontSize: "16px",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        lineHeight: "26px",
                      }}
                    >
                      Contact us at: <br />
                      0044330 043 1210 <br />
                      Info@petshelpsquad.co.uk <br />
                      Address:
                      <br />
                      36-38 Cornhill, London, England, EC3V 3NG, United Kingdom
                    </Typography>
                  </div>
                  <div className="secondary-color secondary-title margin-left-1 margin-top-1">
                    {/* <div className="heading sub-heading blackish-color font-weight-700 margin-bottom-2 ">
                      Social Links
                    </div> */}
                    <Link href="https://www.facebook.com/petshelpsquad">
                      <img src="../icons/Facebook.svg" alt="facebook"></img>
                    </Link>
                    <Link href="https://www.linkedin.com/company/petshelpsquad">
                      <img
                        src="../icons/Inkedin.svg"
                        alt="LinkedIn"
                        className="margin-left-1"
                      ></img>
                    </Link>
                    <Link href="https://x.com/Petshelpsquad">
                      <img
                        src="../icons/Twitter.svg"
                        alt="Twitter"
                        className="margin-left-1"
                      ></img>
                    </Link>
                    <Link href="https://www.youtube.com/@Pets_New_Look/featured">
                      <img
                        src="../icons/youtube.svg"
                        alt="youtube"
                        className="margin-left-1"
                      ></img>
                    </Link>
                    <Link href="https://www.instagram.com/petshelpsquad/">
                      <img
                        src="../icons/Instagaram.svg"
                        alt="instagram"
                        className="margin-left-1"
                      ></img>
                    </Link>
                  </div>
                </Grid>
                <Grid item lg={3} md={3} xs={12} xl={3} sm={6}>
                  <FooterLinks name="Our Services" linksData={servicesData} />
                </Grid>
                <Grid item lg={3} md={3} xs={12} xl={3} sm={6}>
                  <FooterLinks name="Site links" linksData={SiteLinks} />
                </Grid>
              </Grid>
            </Grid>
            <Grid mt="95px" item xs={12}>
              <Divider sx={{ bgcolor: "rgba(193, 193, 199, 0.18)" }} />
              <div className="footer-end margin-top-2-5 flex justify-space-around margin-bottom-2">
                <div className="footer">
                  Copyright@ Pets Help Squad, All rights reserved
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
