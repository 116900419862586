import React, { useEffect } from "react";
import { Link } from "@mui/material";

export const FooterLinks = ({ name, linksData }) => {
  useEffect(() => {
    scrollToHash();
  }, []);

  const scrollToHash = () => {
    const hash = window.location.hash;
    if (hash) {
      const target = document.querySelector(hash);
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  return (
    <>
      <div className="footer-links-parent">
        <div className="heading sub-heading blackish-color font-weight-700 margin-top-1 ">
          {name}
        </div>
        <div className="links">
          {linksData?.map((item) => (
            <div key={item.id} className="link-parent flex margin-top-0-8">
              {item.icon && (
                <div className="icon margin-right-0">{item.icon}</div>
              )}
              <Link
                href={item.path}
                sx={{ fontSize:"16px", textDecoration: "none", color: "#000958"}}
                className="link"
              >
                {item.title}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
