import React from "react";
import { Grid, Typography, Container } from "@mui/material";

function AboutUsMission() {
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          maxWidth: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 15,
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{ backgroundColor: "#F3E9D7", borderRadius: "20px", paddingY: 5 }}
        >
          <Grid item xs={12} md={6}>
            <Typography
              style={{
                fontSize: "40px",
                fontFamily: "Roboto",
                lineHeight: "50px",
                // marginTop: "40px",
                marginBottom: "40px",
                fontWeight: 700,
                color: "#000958",
              }}
            >
              Our Mission
              <img
              src="../about/aboutSmallIcon.svg"
              alt="about us"
              style={{ width: "100%", maxWidth: "fit-content" }}
            />
            </Typography>

            <Typography variant="p" className="our-mission-para">
              Our vision is to connect all pet owners and their dogs onto one
              platform, where they can locate all associated services in one
              app. It was created with all the wants and requirements of pets in
              mind. Pets Help Squad is possibly the best one-stop shop for
              finding the best services for your pet. The product will help to
              create a future where humans and their pets may live together in
              harmony for the benefit of all.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} textAlign="center">
            <img
              src="../about/about-second.png"
              alt="about us"
              style={{ width: "100%", maxWidth: "fit-content" }}
            />
          </Grid>
        </Grid>
        {/* <Grid container xs={12} spacing={4} style={{ marginTop: "40px" }}>
          <Container
            maxWidth={false}
            sx={{
              maxWidth: "90%",
              display: "flex",
              flexDirection: ["column", null, "row"],
              background: "#F3E9D7",
              borderRadius: "20px",
            }}
          >
            <Grid item lg={6.5} md={7} xs={12} xl={6.5} sm={12}>
              <Typography
                style={{
                  fontSize: "40px",
                  fontFamily: "Roboto",
                  lineHeight: "50px",
                  marginTop: "40px",
                  fontWeight: 700,
                  color: "#000958",
                }}
              >
                Our Mission
              </Typography>

              <p className="our-mission-para">
                Our vision is to connect all pet owners and their dogs onto one
                platform, where they can locate all associated services in one
                app. It was created with all the wants and requirements of pets
                in mind. Pets Help Squad is possibly the best one-stop shop for
                finding the best services for your pet. The product will help to
                create a future where humans and their pets may live together in
                harmony for the benefit of all.
              </p>
            </Grid>
            <Grid
              item
              lg={5.5}
              md={7}
              xs={12}
              xl={5.5}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                allignItems: "center",
                background: "#F3E9D7",
              }}
            >
              <img
                src="../about/about-second.png"
                alt="about us"
                className="about-main-image"
              />
            </Grid>
          </Container>
        </Grid> */}
      </Container>
    </>
  );
}

export default AboutUsMission;
