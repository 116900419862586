import React from 'react'
import UnderConstruction from '../components/UnderConstruction'

const UnderConstructionPage = () => {
  return (
    <div>
    <UnderConstruction/>
    </div>
  )
}

export default UnderConstructionPage