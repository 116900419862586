import {
  Box,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import dayCare from "../../assets/svg/service-icons/dayCare.svg";
import walking from "../../assets/svg/service-icons/walking.svg";
import boarding from "../../assets/svg/service-icons/boarding.svg";
import drop from "../../assets/svg/service-icons/drop.svg";
import house from "../../assets/svg/service-icons/house.svg";
const petSittingData = [
  {
    icon: dayCare,
    title: "Day Care",
    description:
      "Your dog spends the day at your sitter’s home. Drop them off in the morning and pick up a happy pup in the evening.",
  },
  {
    icon: walking,
    title: "Walking",
    description:
      "Your dog gets a walk around your neighbourhood. Perfect for busy days and dogs with extra energy to burn.",
  },
  {
    icon: boarding,
    title: "Boarding",
    description:
      "Your pets stay overnight in your sitter’s home. They’ll be treated like part of the family in a comfortable environment.",
  },
  {
    icon: drop,
    title: "Drop-in Visits",
    description:
      "Your sitter drops by your home to play with your pets, offer food, and give potty breaks or clean the litter box.",
  },
  {
    icon: house,
    title: "House Sitting",
    description:
      "Your sitter takes care of your pets and your home. Your pets will get all the attention they need without leaving home.",
  },
];
function PetSittingServices() {
  return (
    <Box sx={{ backgroundColor: "#eeeef3", marginTop: "50px", paddingBottom: "50px" }}>
      <Container
        maxWidth={false}
        sx={{
          maxWidth: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        id="pet-sitting-services"
      >
        <Grid container spacing={5} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6}>
            <img
              src="../service-page/pet-setting.svg"
              className="services-image"
              alt="pet setting image"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontSize: "40px",
                fontFamily: "Roboto",
                lineHeight: "50px",
                fontWeight: 600,
                color: "#000958",
                marginTop: "60px",
              }}
            >
              Pet Sitting Services
            </Typography>
            <Box variant="" className="grooming-para">
              Our reliable pet sitters are here to ensure your pets are well
              cared for when you are not around. We offer a range of pet sitting
              services to suit your needs:
            </Box>
          </Grid>
          {petSittingData?.map((item) => (
            <Grid item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  height: "100%",
                  minHeight: "280px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "30px",
                  borderRadius: "20px",
                  backgroundColor: "#fafafa",
                }}
              >
                <CardMedia
                  component="img"
                  image={item?.icon}
                  alt="bathing"
                  sx={{
                    width: "100px",
                    height: "100px",
                  }}
                />
                <CardContent sx={{ textAlign: "center" }}>
                  <Typography
                   sx={{
                    fontSize: "25px",
                    fontFamily: "Roboto",
                    lineHeight: "24px",
                    fontWeight: 500,
                    marginTop: "20px",
                    marginBottom: "20px",
                    color: "#000958",
                  }}
                  >
                    {item?.title}
                  </Typography>
                  <Typography
                   sx={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    lineHeight: "28px",
                    fontWeight: 400,
                    marginTop: "20px",
                    marginBottom: "20px",
                    color: "#6B7280",
                  }}
                  >
                    {item.description}
                  </Typography>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default PetSittingServices;
