import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Grid, Card, Typography, Button, Link } from "@mui/material";

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import CustomTextField from "../../../CustomTextField/CustomTextField";
import { BASE_API } from "../../../../config";

function Groomer({ signUpUrl }) {
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      contactNumber: "",
      email: "",
      companyName: "",
      crn: "",
      address: "",
      password: "123",
      companyShortCode: "PHS",
    },
  });

  const onSubmit = async (formValues) => {
    try {
      const response = await fetch(`${BASE_API}auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });

      const responseData = await response.json();

      if (responseData?.data !== null) {
        toast.success(
          responseData?.message ?? `Sign Up Successfully!. Check Your Email.`
        );
        navigate(`/verification-timer?userId=${responseData?.data?.userId}`);
        methods.reset();
      } else {
        toast.error(responseData.message || responseData.errors[0]);
      }
    } catch (error) {
      const errMsg = error?.message;
      toast.error(errMsg);
    }
  };

  const lineStyle = {
    width: "191px",
    borderBottom: "3px solid #DECDC6",
  };
  return (
    <Card
      sx={{
        // maxWidth: "500px",
        p: { md: "40px", sm: "20px", xs: "10px" },
        boxShadow: "0px 31.00949px 64px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "12px",
      }}
    >
      <Box mb={3} className="flex flex-column align-center center-text" gap={2}>
        <Typography className="primary-color secondary-heading font-weight-600 font-family-Work">
          Groomer
        </Typography>
        <Typography className="primary-color font-weight-500 font-family-Work">
          <div style={lineStyle}></div>
        </Typography>
        <Typography className="primary-color font-weight-400 font-family-Work">
          Welcome to Pet Help Squad groomer platform! Create your account,
          showcase your identity, and let's bring your cinematic visions to
          life.
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              className="margin-top-0-2"
            >
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  required
                  name="firstName"
                  label="First Name"
                  placeholder="Enter your First Name"
                  rules={{
                    required: "Field is required",
                    pattern: {
                      value: /^[A-Za-z]+$/,
                      message: "only alphabets are allowed",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  required
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter your Last Name"
                  rules={{
                    required: "Field is required",
                    pattern: {
                      value: /^[A-Za-z]+$/,
                      message: "only alphabets are allowed",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  required
                  name="email"
                  label="Email"
                  placeholder="Enter your Email"
                  rules={{
                    required: "Field is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
              <CustomTextField
                  fullWidth
                  required
                  name="password"
                  label="Password"
                  placeholder="Enter your Last Name"
                  rules={{
                    required: "Field is required",
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message: "Password must contain at least one capital letter, one small letter, one numeric value, and one special character."
                    },
                  }}
                />
              </Grid>
             
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  required
                  name="contactNumber"
                  label="Phone Number"
                  placeholder="Enter your UK Phone Number +441234567890"
                  rules={{
                    required: "Field is required",
                    pattern: {
                      value: /^\+44\d{9,10}$/,
                      message: "Invalid UK phone number",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  required
                  name="companyName"
                  label="Business Name"
                  placeholder="Enter your Business Name"
                  rules={{ required: "Field is required" }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  required
                  name="crn"
                  label="Company Registration Number"
                  placeholder="Registration Number"
                  rules={{ required: "Field is required" }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  name="address"
                  label="Address"
                  placeholder="Enter  your address"
                  rules={{ required: "Field is required" }}
                />
              </Grid>

              <Grid
                mt={2}
                item
                xl={12}
                xs={12}
                display={"flex"}
                justifyContent={"center"}
              >
                <Button
                  type="submit"
                  sx={{ py: "10px" }}
                  className="heading-18 width-100 margin-top-0 bg-primary-color white-color font-family-work font-weight-500 border-radius-8"
                >
                  Sign Up
                </Button>
              </Grid>
              <Typography className=" font-weight-400 font-family-plus text-align-c">
                Already have an account ?{" "}
                <Button
                  href="/login"
                  variant="text"
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "600",
                    color: "#000958",
                  }}
                >
                  Login
                </Button>
              </Typography>
              <Typography
                className=" font-weight-400 font-family-plus text-align-c"
                textAlign="center"
              >
                By continuing to create account you are agree to Pet Help Squad{" "}
                <br />
                <Link
                  href="/terms-and-conditions"
                  className="primary-color font-weight-600 text-decoration"
                  variant="text"
                >
                  Terms & Conditions {""}
                </Link>
                and {""}
                <Link
                  href="/privacy-policy"
                  className="primary-color font-weight-600 text-decoration"
                  variant="text"
                >
                  Privacy Policy
                </Link>
              </Typography>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </Card>
  );
}

export default Groomer;
