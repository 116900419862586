import * as React from "react";
import { Typography } from "@mui/material";
import AboutUsPawsSection from "../components/AboutusPawsSection";
import AboutUsMission from "../components/AboutusMission";
import AboutWhySection from "../components/AboutWhysection";
import OurTeam from "../components/ourTeam";
export default function About() {
  return (
    <>
      <Typography
        className="backPic"
        style={{
          fontSize: "40px",
          fontFamily: "Roboto",
          lineHeight: "50px",
          fontWeight: 600,
          color: "#000958",
          marginTop: "60px",
          marginBottom: "40px",
          textAlign: "center",
        }}
      >
        Paws-Itively Complete Pet Care Solutions
      </Typography>
      <AboutUsPawsSection />
      <AboutUsMission />
      <OurTeam />
      <AboutWhySection />
    </>
  );
}
