import React from "react";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";

import Button from "@mui/material/Button";

import { Box, Grid } from "@mui/material";
const MostPopular = [
  {
    decription:
      "Expanded access to pet services: comprehensive grooming services, pet boarding facilities, veterinary care consultations (non-emergency), and discounted pet daycare options.",

    id: 1,
  },
  {
    decription:
      "Full access to community pet clinics and participation in pet events like meetups and pet birthday parties.",
    id: 2,
  },
  {
    decription:
      "In-depth pet caregiver training resources, including videos, articles, and online courses..",
    id: 3,
  },
];
const Basic = [
  {
    decription:
      "Access to the platform's pet breeder, shelter, and rescue home connections to find your perfect pet.",

    id: 1,
  },
  {
    decription:
      "Limited access to pet services: basic grooming services and pet clinics.",
    id: 2,
  },
  {
    decription:
      "Access to community forums and basic training resources for pet caregivers.",
    id: 3,
  },
  {
    decription: "Standard verification checks for pet adoptions.",
    id: 4,
  },
];
const Pro = [
  {
    decription:
      "All features of the Pet Care Plus Plan.Limited access to pet services: basic grooming services and pet clinics.",

    id: 1,
  },
  {
    decription:
      "Unlimited access to premium pet services: top-tier grooming, luxury pet boarding, 24/7 emergency veterinary care consultations, and complimentary pet daycar",
    id: 2,
  },
  {
    decription:
      "Exclusive access to VIP community pet clinics and guaranteed spots at all pet events, including dating, funerals, and fashion accessory.",
    id: 3,
  },
];
function PricingCards() {
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        flexDirection: "column",
      }}
    >
      <div className="contact-us">
        <img src="../contact-page/Rectangle.svg" alt="petdog"></img>
        <Typography
          sx={{
            color: "#37393F",

            fontSize: "18px",

            fontFamily: "Roboto",
            fontWeight: 500,
            lineHeight: "22px",
            marginLeft: "10px",
          }}
        >
          Simple Pricing Option
        </Typography>
      </div>
      <Typography
        sx={{
          color: "#000958",
          display: "flex",
          textAlign: "center",
          fontSize: "40px",
          maxWidth: "538px",
          fontFamily: "Roboto",
          fontWeight: 700,
          lineHeight: "49px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        Choose the Right Plan for Your pets
      </Typography>
      <Grid
        container
        xs={12}
        spacing={4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          lg={4}
          md={12}
          xs={12}
          xl={4}
          sm={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Card
            sx={{
              width: "372px",
              height: "680px",
              borderRadius: "20px 20px 20px 20px",
            }}
          >
            <Box sx={{ borderRadius: "0px 0px 20px 20px", height: "100%" }}>
              <CardContent>
                <Typography
                  sx={{
                    color: "#000958",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Roboto",
                    fontSize: 24,
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "33px",
                    width: "100%",
                  }}
                >
                  Basic
                </Typography>

                <img src="../pricing/Line.png" alt="line"></img>
                <Typography
                  sx={{
                    color: "#000958",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                    fontSize: "40px",
                    fontStyle: "normal",
                    marginTop: "20px",
                    fontWeight: 700,
                    lineHeight: "22px",
                    width: "100%",
                  }}
                >
                  £7.99 / month
                </Typography>
                <Box
                  sx={{
                    padding: "7px 32px 7px 32px",
                    background: "#F2514F",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                    fontSize: "40px",
                    marginTop: "30px",
                    height: "42px",
                    width: "258px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      lineHeight: "29px",
                      fontWeight: 400,
                    }}
                  >
                    Billed as £199 per year
                  </Typography>
                </Box>
                <img src="../pricing/Line.png" alt="line"></img>
              </CardContent>
              {Basic?.map((item) => (
                <div key={item.id} className="check-img-basic">
                  <img src="../pricing/check.svg" alt="line"></img>

                  <Typography
                    sx={{
                      color: "#929292",
                      display: "flex",

                      justifyContent: "center",
                      fontFamily: "Roboto",
                      fontSize: "18px",
                      lineHeight: "25px",
                      fontWeight: 400,
                    }}
                    className="margin-left-1 "
                  >
                    {item.decription}
                  </Typography>
                </div>
              ))}
              <div className="get-startedButton">
                <Box
                  sx={{
                    padding: "16px 32px 16px 32px",
                    gap: "10px",
                    width: "156px",
                    height: "60px",
                    borderRadius: "6px",
                    background: "#000958",
                    display: "flex",
                    marginTop: "35px",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: 0.8,
                  }}
                >
                  <Button
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "18px",

                      lineHeight: "28px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                    }}
                  >
                    Get Started
                  </Button>
                </Box>
              </div>
            </Box>
          </Card>
        </Grid>

        <Grid
          item
          lg={4}
          md={12}
          xs={12}
          xl={4}
          sm={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Card
            sx={{
              width: "372px",
              height: "837px",
              borderRadius: "20px 20px 20px 20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "20px 20px 0px 0px",
                background: "#F2514F",
                padding: "10px 126px 10px 126px",
                height: "42px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  display: "flex",

                  justifyContent: "center",

                  alignItems: "center",

                  textTransform: "none",

                  color: "#FFFFFF",

                  fontSize: "20px",
                  fontWeight: 600,

                  lineHeight: "30px",
                }}
              >
                Most Popular
              </Typography>
            </Box>

            <Box
              sx={{
                background: "#2C3271",
                borderRadius: "0px 0px 20px 20px",
                height: "100%",
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Roboto",
                    fontSize: 24,
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "33px",
                    width: "100%",
                  }}
                >
                  Advanced
                </Typography>

                <img src="../pricing/Line.png" alt="line"></img>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                    fontSize: "40px",
                    fontStyle: "normal",
                    marginTop: "20px",
                    fontWeight: 700,
                    lineHeight: "22px",
                    width: "100%",
                  }}
                >
                  £9.99/ month
                </Typography>
                <Box
                  sx={{
                    padding: "7px 32px 7px 32px",
                    background: "#FFFFFF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                    fontSize: "40px",
                    marginTop: "30px",
                    height: "42px",
                    width: "258px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#F2514F",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      lineHeight: "29px",
                      fontWeight: 400,
                    }}
                  >
                    Billed as £199 per year
                  </Typography>
                </Box>
                <img src="../pricing/Line.png" alt="line"></img>
              </CardContent>
              {MostPopular?.map((item) => (
                <div key={item.id} className="check-img">
                  <img src="../pricing/check.svg" alt="line"></img>

                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      display: "flex",

                      justifyContent: "center",
                      fontFamily: "Roboto",
                      fontSize: "18px",
                      lineHeight: "25px",
                      fontWeight: 400,
                    }}
                    className="margin-left-1 "
                  >
                    {item.decription}
                  </Typography>
                </div>
              ))}
              <div className="get-startedButton">
                <Box
                  sx={{
                    padding: "16px 32px 16px 32px",
                    gap: "10px",
                    width: "156px",
                    height: "60px",
                    borderRadius: "6px",
                    background: "#FFFFFF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: 0.8,
                    "&:hover": {
                      fontWeight: 500,
                      background: "#FFFFFF",
                      opacity: 1,
                    },
                  }}
                >
                  <Button
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "18px",

                      lineHeight: "28px",
                      fontWeight: 600,
                      color: "#000958",
                    }}
                  >
                    Get Started
                  </Button>
                </Box>
              </div>
            </Box>
          </Card>
        </Grid>

        <Grid
          item
          lg={4}
          md={12}
          xs={12}
          xl={4}
          sm={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Card
            sx={{
              width: "372px",
              height: "770px",
              borderRadius: "20px 20px 20px 20px",
            }}
          >
            <Box sx={{ borderRadius: "0px 0px 20px 20px", height: "100%" }}>
              <CardContent>
                <Typography
                  sx={{
                    color: "#000958",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Roboto",
                    fontSize: 24,
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "33px",
                    width: "100%",
                  }}
                >
                  Pro
                </Typography>

                <img src="../pricing/Line.png" alt="line"></img>
                <Typography
                  sx={{
                    color: "#000958",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                    fontSize: "40px",
                    fontStyle: "normal",
                    marginTop: "20px",
                    fontWeight: 700,
                    lineHeight: "22px",
                    width: "100%",
                  }}
                >
                  £7.99 / month
                </Typography>
                <Box
                  sx={{
                    padding: "7px 32px 7px 32px",
                    background: "#F2514F",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                    fontSize: "40px",
                    marginTop: "30px",
                    height: "42px",
                    width: "258px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      lineHeight: "29px",
                      fontWeight: 400,
                    }}
                  >
                    Billed as £199 per year
                  </Typography>
                </Box>
                <img src="../pricing/Line.png" alt="line"></img>
              </CardContent>
              {Pro?.map((item) => (
                <div key={item.id} className="check-img-basic">
                  <img src="../pricing/check.svg" alt="line"></img>

                  <Typography
                    sx={{
                      color: "#929292",
                      display: "flex",

                      justifyContent: "center",
                      fontFamily: "Roboto",
                      fontSize: "18px",
                      lineHeight: "25px",
                      fontWeight: 400,
                    }}
                    className="margin-left-1 "
                  >
                    {item.decription}
                  </Typography>
                </div>
              ))}
              <div className="get-startedButton">
                <Box
                  sx={{
                    padding: "16px 32px 16px 32px",
                    gap: "10px",
                    width: "156px",
                    height: "60px",
                    borderRadius: "6px",
                    background: "#000958",
                    marginTop: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: 0.8,
                  }}
                >
                  <Button
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "18px",

                      lineHeight: "28px",
                      fontWeight: 600,
                      color: "#FFFFFF",
                    }}
                  >
                    Get Started
                  </Button>
                </Box>
              </div>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PricingCards;
