import React from "react";

import Card from "@mui/material/Card";

import Typography from "@mui/material/Typography";

import { Box, Grid, Container } from "@mui/material";

function AboutWhySection() {
  return (
    <Container
      maxWidth={false}
      padding={false}
      sx={{
        padding: "0px",
        maxWidth: "100%",
        display: "flex",
        background: "#EEEEF3",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "40px",
      }}
    >
      <Typography
        sx={{
          textTransform: "none",

          color: "#000958",

          fontSize: "40px",

          marginTop: "100px",
          fontFamily: "Roboto",

          fontWeight: 700,
          height: "50px",
        }}
      >
        Why Us
      </Typography>
      <Grid
        sx={{
          marginTop: "100px",
          marginBottom: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        container
        xs={12}
      >
        <Grid
          lg={3}
          md={6}
          xs={12}
          xl={3}
          sm={6}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Card
            sx={{
              width: "274px !important",
              borderRadius: "24px",
              padding: "32px",
              gap: "30px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  borderRadius: "2px",

                  display: "flex",

                  justifyContent: "start",

                  alignItems: "center",

                  textTransform: "none",

                 color: "#F2514F",

                  fontSize: "32px",

                  fontFamily: "Roboto",

                  fontWeight: 700,
                  height: "30px",
                }}
              >
                01.
              </Typography>

              <Typography
                sx={{
                  color: "#3E3E3E",

                  fontSize: "24px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  fontFamily: "Roboto",

                  fontWeight: 500,
                  height: "30px",
                }}
              >
                Pet Sitting facilities near you
              </Typography>
            </Box>

            <p
              className="about-card-para"
              style={{
                fontFamily: "Roboto",
                fontWeight: 400,
                marginTop: "60px",
                fontSize: "16px",
                lineHeight: "28px",
                letterSpacing: "1%",
                color: "#929292",
              }}
            >
              Our staff is trained to handle all types of pets and provide
              individual attention and care to each pet in our care. We offer
              daily exercise and playtime to ensure that your pet gets the
              attention and exercise they need to stay healthy and happy.
            </p>
          </Card>
        </Grid>
        <Grid
          item
          lg={3}
          md={6}
          xs={12}
          xl={3}
          sm={6}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src="../about/abouticon-page.png"
            alt="aboutcards"
            className="card-icon"
          />
          <Card
            sx={{
              width: "274px !important",
              borderRadius: "24px",
              padding: "32px",
              gap: "30px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  borderRadius: "2px",

                  display: "flex",

                  justifyContent: "start",

                  alignItems: "center",

                  textTransform: "none",

                 color: "#F2514F",

                  fontSize: "32px",

                  fontFamily: "Roboto",

                  fontWeight: 700,
                  height: "30px",
                }}
              >
                02.
              </Typography>

              <Typography
                sx={{
                  color: "#3E3E3E",

                  fontSize: "24px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  fontFamily: "Roboto",

                  fontWeight: 500,
                  height: "30px",
                }}
              >
                Pet grooming services near you
              </Typography>
            </Box>

            <p
              className="about-card-para"
              style={{
                fontFamily: "Roboto",
                fontWeight: 400,
                marginTop: "60px",
                fontSize: "16px",
                lineHeight: "28px",
                letterSpacing: "1%",
                color: "#929292",
              }}
            >
              If you're looking for a reliable and professional pet grooming
              service near you, look no further than Pets Help Squad Our
              experienced and trained groomers provide a variety of grooming
              services for all types of pets, including dogs, cats, rabbits, and
              more.
            </p>
          </Card>
        </Grid>
        <Grid
          lg={3}
          md={6}
          xs={12}
          xl={3}
          sm={6}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Card
            sx={{
              width: "274px !important",
              borderRadius: "24px",
              padding: "32px",
              gap: "30px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  borderRadius: "2px",

                  display: "flex",

                  justifyContent: "start",

                  alignItems: "center",

                  textTransform: "none",

                 color: "#F2514F",

                  fontSize: "32px",

                  fontFamily: "Roboto",

                  fontWeight: 700,
                  height: "30px",
                }}
              >
                03.
              </Typography>

              <Typography
                sx={{
                  color: "#3E3E3E",

                  fontSize: "24px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  fontFamily: "Roboto",

                  fontWeight: 500,
                  height: "30px",
                }}
              >
               Pet Boarding facilities near you
              </Typography>
            </Box>

            <p
              className="about-card-para"
              style={{
                fontFamily: "Roboto",
                fontWeight: 400,
                marginTop: "60px",
                fontSize: "16px",
                lineHeight: "28px",
                letterSpacing: "1%",
                color: "#929292",
              }}
            >
              Pet daycare is an excellent option for pet owners who work long
              hours or have other commitments that prevent them from being home
              with their pets during the day. It's also a great way to socialize
              pets and improve their behavior and obedience through interaction
              with other pets and trained staff members.
            </p>
          </Card>
        </Grid>
        <Grid
          lg={3}
          md={6}
          xs={12}
          xl={3}
          sm={6}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src="../about/abouticon-page.png"
            alt="aboutcards"
            className="card-icon"
          ></img>
          <Card
            sx={{
              width: "274px !important",
              borderRadius: "24px",
              padding: "32px",
              gap: "30px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  borderRadius: "2px",

                  display: "flex",

                  justifyContent: "start",

                  alignItems: "center",

                  textTransform: "none",

                 color: "#F2514F",

                  fontSize: "32px",

                  fontFamily: "Roboto",

                  fontWeight: 700,
                  height: "30px",
                }}
              >
                04.
              </Typography>

              <Typography
                sx={{
                  color: "#3E3E3E",

                  fontSize: "24px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  fontFamily: "Roboto",

                  fontWeight: 500,
                  height: "30px",
                }}
              >
                Community pet clinics
              </Typography>
            </Box>

            <p
              className="about-card-para"
              style={{
                fontFamily: "Roboto",
                fontWeight: 400,
                marginTop: "60px",
                fontSize: "16px",
                lineHeight: "28px",
                letterSpacing: "1%",
                color: "#929292",
              }}
            >
              At Community Pets Clinic, we offer a range of services, including
              vaccinations, wellness exams, parasite prevention, dental care,
              and more. Our veterinarians are trained to handle a wide variety
              of pet health issues, from routine checkups to more complex
              medical conditions.
            </p>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AboutWhySection;
