import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import SVG1 from "../assets/svg/section4/svg1.svg";
import SVG2 from "../assets/svg/section4/svg1.svg";
import { NavLink } from "react-router-dom";

const StatusPage = ({ userData }) => {
  const lineStyle = {
    width: "191px",
    height: "3px",
    backgroundColor: "#DECDC6",
  };
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  useEffect(() => {
    if (isEmpty(userData)) {
      window.history.back();
    }
  }, [userData]);
  return (
    <Grid
      container
      className="justify-space-around"
      sx={{ mt: 7, bgcolor: "#1C3E6C" }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "end",
          mr: { lg: "100px", xs: "30px" },
        }}
      >
        <img src={SVG2} width={"auto"} alt="" />
      </Grid>
      <Grid
        item
        xs={12}
        className="flex flex-column justify-center align-center"
        sx={{ gap: "1rem", px: { md: 0, sm: "10px", xs: "6px" } }}
      >
        <Typography className="font-weight-400 font-family-dm white-color center-text heading-20">
          We appreciate your interest and are pleased to inform you that your
          query has been successfully submitted.
        </Typography>
        <Typography className="font-weight-400 font-family-dm white-color center-text heading-30">
          Your verification status is :
          <Typography
            className="font-weight-400 font-family-dm white-color center-text heading-20"
            component="span"
          >
            {" "}
            {userData?.verificationStatus}
          </Typography>
        </Typography>
        <div style={lineStyle}></div>
        <Typography
          sx={{
            maxWidth: "1249px",
            px: { md: "0", xs: "2rem" },
          }}
          className="white-color font-weight-400 font-family-lato center-text heading-20"
        >
          One of our sales agents will review your information and contact you
          shortly to assist with your request.
        </Typography>
        <Typography
          sx={{
            maxWidth: "1249px",
            px: { md: "0", xs: "2rem" },
          }}
          className="white-color font-weight-400 font-family-lato center-text heading-20"
        >
          If you have any further questions or need immediate assistance, please
          do not hesitate to reach out to us at{" "}
          <Typography
            className="white-color font-weight-400 font-family-lato center-text heading-20"
            component="span"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            href="/contact-us"
          >
            <NavLink to="/contact">Contact Us .</NavLink>
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ ml: { lg: "100px", xs: "30px" }, mb: "-3px" }}>
        <img src={SVG1} width={"auto"} alt="" />
      </Grid>
    </Grid>
    // <Box
    //   sx={{ background: "radial-gradient( #151C48, #151C48)" }}
    // //   height={"100vh"}
    // >
    //   <Box
    //     sx={{
    //       backgroundPosition: "right center",
    //       backgroundSize: 1000,
    //       pt: {
    //         sm: 25,
    //         xs: 20,
    //       },
    //       pb: {
    //         md: 10,
    //         xs: 3,
    //       },
    //     }}
    //   >
    //     <Container
    //       maxWidth={false}
    //       sx={{
    //         maxWidth: {
    //           md: "90%",
    //           xs: "100%",
    //         },
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Stack
    //         alignItems="center"
    //         direction="column"
    //         spacing={0}
    //         textAlign="center"
    //       >
    //         <Stack
    //           direction="column"
    //           alignItems="center"
    //           spacing={3}
    //           sx={{
    //             pb: {
    //               md: 20,
    //               sm: 15,
    //               xs: 10,
    //             },
    //           }}
    //         >
    //           <Typography
    //             variant="h4"
    //             sx={{
    //               letterSpacing: "-1.2px",
    //               fontWeight: 600,
    //             }}
    //             color="common.white"
    //           >
    //             Your Verification
    //           </Typography>
    //           <Typography
    //             variant="h4"
    //             sx={{
    //               letterSpacing: "-1.2px",
    //               fontWeight: 600,
    //             }}
    //             color="common.white"
    //           >
    //             {userData?.verificationStatus}
    //           </Typography>
    //         </Stack>
    //       </Stack>
    //     </Container>
    //   </Box>
    // </Box>
  );
};

export default StatusPage;
