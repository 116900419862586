import { Box, Typography } from "@mui/material";
// import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow-down.svg";
import React from "react";
import { NavLink } from "react-router-dom";

export const LinkDropdown = ({ menu }) => {
  console.log(menu.href, "new");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          "&:hover": { fontWeight: 500, color: "#000958" },
          fontWeight: Boolean(anchorEl) ? 500 : 400,
          transition: "all .1s ease",
          color: Boolean(anchorEl) ? "#292D32" : "#6B7280",
          paddingY:"35px"
        }}
        className="cursor-pointer flex align-center"
        aria-haspopup="true"
        onClick={menu.menuItems ? handleClick : menu.onClick}
      >
        <div className="padding-right-0">
          <NavLink
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? 600 : 400,
                color: isActive ? "#000958" : "#616161",
              };
            }}
            to={menu.href}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontFamily: "Roboto",
                letterSpacing: "1.1%",
                lineHeight: "22px",
              }}
            >
              {menu.name}
            </Typography>
          </NavLink>
        </div>
      </Box>
    </>
  );
};
