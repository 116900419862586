import { Box, Typography } from "@mui/material";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function PrivacyPolicy() {
  return (
    <React.Fragment>
      <Box
        sx={{
          px: { xs: 2, sm: 5, md: 25, lg: 30, xl: 40 },
          paddingBottom: 5,
        }}
      >
        <Box sx={{ textAlign: "center", marginY: "60px" }}>
          <Typography
            style={{
              fontSize: "40px",
              fontFamily: "Roboto",
              lineHeight: "50px",
              fontWeight: 600,
              color: "#000958",
            }}
          >
            Privacy Policy
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          Introduction
        </Typography>
        <Typography
          variant="p"
          sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
        >
          Welcome to petshelpsquad.co.uk is operated by Pay io Services LTD
          ("We"). We are registered in England and Wales under company number
          15439659 with its registered office at International House, 36-38
          Cornhill, London, England, EC3V 3NG, United Kingdom. We are committed
          to safeguarding the privacy of our users; this policy details how we
          will treat your personal information.
        </Typography>
        <br />
        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          1. Information We Collect
        </Typography>
        <Typography>
          We may collect, store, and use the following kinds of personal data:
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Information about
          your device and visits : {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            This includes your IP address, geographical location, browser type
            and version, operating system, referral source, length of visit,
            page views, and website navigation paths.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Information you
          provide : {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            This includes your email address, phone number, address, and any
            other details you provide when registering or making a booking.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Transactional data
          : {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            Details of services you have purchased or sold through our site,
            including the date and time of purchase, service details, and
            payment information.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Profile information
          : {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            This includes your profile details, such as your name, profile
            pictures, booking history, and reviews.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          2. How We Use Your Information
        </Typography>
        <Typography>We use your personal information to:</Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Provide our
          services : {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            Manage your bookings, process payments, and communicate with you
            about your orders
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Improve user
          experience : {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            Personalise the website for you and ensure it displays correctly on
            your device.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Marketing : {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            Send you marketing communications, where you have specifically
            agreed to this.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Security : {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            Keep our website secure and prevent fraud.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          3. Disclosing Your Information
        </Typography>
        <Typography>We may disclose your personal information:</Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> To service
          providers : {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            Such as groomers, pet sitters, vet doctors, and sellers, for the
            purpose of fulfilling your service requests.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> To comply with the
          law : {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            If required by law or in response to a legal request.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> To protect our
          rights : {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            If necessary to establish, exercise, or defend our legal rights.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          4. Security of Your Information
        </Typography>
        <Typography
          variant="p"
          sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
        >
          We will take reasonable technical and organisational precautions to
          prevent the loss, misuse, or alteration of your personal information.
          All personal data you provide will be stored on our secure servers.
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          5. Your Rights
        </Typography>
        <Typography>You have the right to:</Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Access your data :{" "}
          {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            Request copies of the personal information we hold about you.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Correct your data :{" "}
          {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            Request that we correct any inaccuracies in your personal data
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Delete your data: :{" "}
          {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            Request that we delete your personal data.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Restrict processing
          : {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            Request that we restrict the processing of your personal data.
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "25px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          <FiberManualRecordIcon style={{ fontSize: 12 }} /> Data portability :{" "}
          {""}
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            Request that we transfer your personal data to another organisation.
          </Typography>
          <Typography
            variant="p"
            sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
          >
            To exercise any of these rights, please contact us at
            Info@petshelpsquad.co.uk.
          </Typography>
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          6. Cookies
        </Typography>
        <Typography
          variant="p"
          sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
        >
          Our website uses cookies to improve your experience and our services.
          Cookies are small files stored on your device that help us understand
          how you use our site. You can control cookies through your browser
          settings.
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          7. Changes to This Policy
        </Typography>
        <Typography
          variant="p"
          sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
        >
          We may update this privacy policy from time to time by publishing a
          new version on our website. Please check this page occasionally to
          ensure you are happy with any changes.
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontSize: "30px",
            color: "#000958",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          8. Contact Us
        </Typography>

        <Typography
          variant="p"
          sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
        >
          If you have any questions about this privacy policy or our treatment
          of your personal information, please write to us by email at
          Info@petshelpsquad.co.uk or by post to:
        </Typography>
        <Typography
          variant="p"
          sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
        >
          Pets Help Squad International House, 36-38 Cornhill, London, England,
          EC3V 3NG, United Kingdom
        </Typography>
        <Typography
          variant="p"
          sx={{ fontSize: "18px", color: "#111827", fontWeight: 400 }}
        >
          Thank you for trusting Pets Help Squad is operated by Pay io Services
          LTD with your personal information.
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default PrivacyPolicy;
