import React from "react";
import { Grid, Container } from "@mui/material";
function AboutUsPawsSection() {
  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: "96%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        container
        xs={12}
        spacing={4}
      >
        <Grid
          item
          lg={6}
          md={12}
          xs={12}
          xl={6}
          sm={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <p className="about-us-para">
            Pets Help Squad is a digital platform that provides a range of
            pet-related services, including helping users connect with good
            breeders, shelters, and rescue homes to find their perfect furry
            friend. The platform also offers a variety of pet services such as
            finding pet daycares, grooming services, pet boarding facilities,
            veterinary care, and community pet clinics.
          </p>
          <p className="about-us-para">
            The platform is committed to animal welfare and is 100% compliant
            with the guidelines set by RSPCA and the Animal Welfare Act. Pets
            Help Squad is unique in that it offers an all-in-one solution for
            pet owners, providing a comprehensive range of pet services on a
            single platform. Pets Help Squad also recognizes the need for proper
            training for pet caregivers and provides special training directed
            towards the people providing care. Additionally, the platform offers
            verification checks and proper invoicing for pet adoptions and
            products.
          </p>
          <p className="about-us-para">
            In addition to its pet-related services, Pets Help Squad also hosts
            a range of pet events, including pet birthday parties, meetups,
            dating, funerals, and fashion accessories. The platform's four major
            objectives are to determine the feasibility of its digital one-stop
            solution, develop a strategy for growth and profitability, establish
            a budget for operations, and offer other services to customers
            associated with the Pets Help Squad platform.
          </p>
        </Grid>
        <Grid item lg={6} md={12} xs={12} xl={6} sm={12} className="mainiamge">
          <img
            src="../about/about-us-images.svg"
            alt="about us"
            className="about-main-image"
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default AboutUsPawsSection;
