import React from "react";
import "./index.scss";
import { Grid, Typography } from "@mui/material";
function WhatWeDo() {
  return (
    <>
      <Grid
        sx={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "60px",
        }}
        container
        // xs={12}
        spacing={4}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          // style={{
          //   justifyContent: "center",
          //   alignItems: "center",
          //   width: "100%",
          // }}
        >
          <img
            src="../assets/homepageImages/dog.svg"
            alt="pet-dog"
            className="about-main-image"
          ></img>
        </Grid>
        <Grid item lg={5} md={12} xs={12} xl={5} sm={12}>
          <Typography
            style={{
              fontSize: "40px",
              fontFamily: "Roboto",
              lineHeight: "50px",
              fontWeight: 600,
              color: "#000958",
            }}
          >
            What We Do
          </Typography>
          <p className="customer-para">
            At Pets Help Squad, we offer a comprehensive array of pet care
            services tailored to you and your cherished pets. From professional
            grooming services brought right to your doorstep, dependable pet
            sitting solutions encompassing day care, boarding, and drop-in
            visits, to expert veterinary care delivered by our team of
            experienced vet doctors, we're committed to ensuring the well-being
            of your furry companions. Moreover, our convenient pet products
            marketplace provides a diverse selection of food, toys, grooming
            supplies, and accessories. With our dedicated customer support team
            on hand to assist you at every stage, Pets Help Squad stands as your
            trusted partner in pet care. Join us today and discover the Pets
            Help Squad difference firsthand!
          </p>
        </Grid>
      </Grid>
    </>
  );
}

export default WhatWeDo;
