import React from "react";
import { Grid, Typography, Container } from "@mui/material";
import groomingTeam from "../assets/svg/our-team/groomingTeam.svg";
import petExperts from "../assets/svg/our-team/petExperts.svg";
import veterinary from "../assets/svg/our-team/veterinary.svg";
import trusted from "../assets/svg/our-team/trusted.svg";
import customerSupport from "../assets/svg/our-team/customer-support.svg";

function OurTeam() {
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          maxWidth: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            textTransform: "none",
            color: "#000958",
            fontSize: "40px",
            marginTop: "100px",
            fontFamily: "Roboto",
            fontWeight: 700,
            height: "50px",
          }}
        >
          Our Team
        </Typography>
        <Typography variant="p" className="our-team-para">
          At Pets Help Squad, we collaborate with a network of passionate and
          skilled professionals from across the UK to ensure that your pets
          receive the best care possible. While our team members may vary based
          on location and availability, we are united by our dedication to
          providing exceptional pet care services. Get to know some of the
          professionals who may be part of your pet care journey:
        </Typography>
        <Grid container spacing={2} marginTop={5}>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
          
            sx={{ backgroundColor: "#eeeef3" }}
          >
            <img src={groomingTeam} alt="grooming" />
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            lg={9}
          
            sx={{ backgroundColor: "#eeeef3" }}
          >
            <Typography
              sx={{
                fontSize: "40px",
                fontFamily: "Roboto",
                lineHeight: "50px",
                marginTop: "40px",
                fontWeight: 600,
                color: "#000958",
              }}
            >
              Grooming Professionals
            </Typography>

            <p className="our-mission-para">
              Our grooming professionals are experienced in keeping your pets
              looking and feeling their best. With expertise in bathing,
              brushing, trimming, and more, they provide top-quality grooming
              services tailored to your pet's needs.
            </p>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            <Typography
              sx={{
                textAlign: { xs: "left", md: "right" },
                fontSize: "40px",
                fontFamily: "Roboto",
                lineHeight: "50px",
                marginTop: "40px",
                fontWeight: 600,
                color: "#000958",
              }}
            >
              Pet Sitting Experts
            </Typography>

            <p className="our-mission-para">
              Our pet sitting experts offer a range of services to ensure your
              pets are well cared for when you're away. From day care to
              overnight boarding, they provide a safe and comfortable
              environment for your furry friends, treating them like family.
            </p>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <img src={petExperts} alt="grooming" />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
          
            sx={{ backgroundColor: "#eeeef3" }}
          >
            <img src={veterinary} alt="grooming" />
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            lg={9}
          
            sx={{ backgroundColor: "#eeeef3" }}
          >
            <Typography
              sx={{
                fontSize: "40px",
                fontFamily: "Roboto",
                lineHeight: "50px",
                marginTop: "40px",
                fontWeight: 600,
                color: "#000958",
              }}
            >
              Veterinary Care Providers
            </Typography>

            <p className="our-mission-para">
              Our veterinary care providers are dedicated to keeping your pets
              healthy and happy. Whether it's vaccinations, routine check-ups,
              or specialised treatments, they offer expert care and advice to
              ensure your pet's well-being.
            </p>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            <Typography
              sx={{
                fontSize: "40px",
                textAlign: { xs: "left", md: "right" },
                fontFamily: "Roboto",
                lineHeight: "50px",
                marginTop: "40px",
                fontWeight: 600,
                color: "#000958",
              }}
            >
              Customer Support Team
            </Typography>

            <p className="our-mission-para">
              Our customer support team is here to assist you every step of the
              way. From answering your questions to helping you find the right
              service provider, they strive to provide friendly and helpful
              assistance to ensure your experience with Pets Help Squad is a
              positive one.
            </p>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <img src={customerSupport} alt="grooming" />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
          
            sx={{ backgroundColor: "#eeeef3" }}
          >
            <img src={trusted} alt="grooming" />
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            lg={9}
          
            sx={{ backgroundColor: "#eeeef3" }}
          >
            <Typography
              sx={{
                fontSize: "40px",
                fontFamily: "Roboto",
                lineHeight: "50px",
                marginTop: "40px",
                fontWeight: 600,
                color: "#000958",
              }}
            >
              Trusted Professionals
            </Typography>

            <p className="our-mission-para">
              We work with a diverse range of professionals, each with their own
              expertise and passion for pet care. Rest assured that whoever you
              choose from our network, you'll be partnering with a trusted
              professional committed to providing excellent service for you and
              your pets.
            </p>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default OurTeam;
