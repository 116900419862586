import React from "react";
import {
  Box,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import bathing from "../../assets/svg/service-icons/bathing-icon.svg";
import brushing from "../../assets/svg/service-icons/brushing.svg";
import haircut from "../../assets/svg/service-icons/haircut.svg";
import nailClipping from "../../assets/svg/service-icons/nailCliping.svg";
import earCleaning from "../../assets/svg/service-icons/earClining.svg";
import teethCleaning from "../../assets/svg/service-icons/teethClining.svg";
import analGland from "../../assets/svg/service-icons/analGland.svg";
import deShedding from "../../assets/svg/service-icons/deShedding.svg";
import flea from "../../assets/svg/service-icons/flea.svg";
import skinConditioning from "../../assets/svg/service-icons/skinConditioning.svg";
import breed from "../../assets/svg/service-icons/breed.svg";
import petMessage from "../../assets/svg/service-icons/petMesage.svg";

const groomingData = [
  {
    icon: bathing,
    title: "Bathing",
    description:
      "Shampooing and conditioning to keep your pet’s coat clean and healthy.",
  },
  {
    icon: brushing,
    title: "Brushing and Detangling",
    description: "Removing loose fur, mats, and tangles from the coat.",
  },
  {
    icon: haircut,
    title: "Haircut or Trimming",
    description:
      "Professional cutting or trimming of fur to your desired length and style.",
  },
  {
    icon: nailClipping,
    title: "Nail Clipping",
    description: "Trimming your pet’s nails to a safe and comfortable length.",
  },
  {
    icon: earCleaning,
    title: "Ear Cleaning",
    description: "Cleaning ears to prevent infections and remove excess wax.",
  },
  {
    icon: teethCleaning,
    title: "Teeth Cleaning",
    description: "Brushing or other dental care to maintain oral health.",
  },
  {
    icon: analGland,
    title: "Anal Gland Expression",
    description: "Emptying the anal glands to prevent discomfort or infection.",
  },
  {
    icon: deShedding,
    title: "De-shedding Treatmentices",
    description:
      "Special treatments to reduce shedding and control loose hair.",
  },
  {
    icon: flea,
    title: "Flea and Tick Treatment",
    description: "Applying products to prevent or eliminate parasites.",
  },
  {
    icon: skinConditioning,
    title: "Skin and Coat Conditioning",
    description:
      "Applying moisturisers or treatments to promote healthy skin and a shiny coat.",
  },
  {
    icon: breed,
    title: "Specialised Breed Grooming",
    description:
      "Grooming tailored to the specific needs and characteristics of different breeds.",
  },
  {
    icon: petMessage,
    title: "Pet Massage",
    description: "Gentle massage for relaxation and improved circulation.",
  },
];

function GroomingServices() {
  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      id="grooming-services"
    >
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontSize: "40px",
              fontFamily: "Roboto",
              lineHeight: "50px",
              fontWeight: 600,
              color: "#000958",
              marginTop: "60px",
            }}
          >
            Grooming Services
          </Typography>
          <Box variant="" className="grooming-para">
            Our experienced groomers provide a variety of grooming services at
            your doorstep. Each groomer is thoroughly vetted and trained to
            ensure your pet receives the best care possible. Our grooming
            services include:
          </Box>
        </Grid>
        <Grid item xs={12} md={6} textAlign="end">
          <img
            src="../service-page/grooming.svg"
            className="services-image"
            alt="grooming services image"
          />
        </Grid>
        {groomingData?.map((item) => (
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                height: "100%",
                minHeight: "280px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "15px",
                borderRadius: "20px",
                backgroundColor: "#fafafa",
              }}
            >
              <CardMedia
                component="img"
                image={item?.icon}
                alt="bathing"
                sx={{
                  width: "100px",
                  height: "100px",
                }}
              />
              <CardContent sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    fontSize: "25px",
                    fontFamily: "Roboto",
                    lineHeight: "24px",
                    fontWeight: 500,
                    marginTop: "20px",
                    marginBottom: "20px",
                    color: "#000958",
                  }}
                >
                  {item?.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    lineHeight: "28px",
                    fontWeight: 400,
                    marginTop: "20px",
                    marginBottom: "20px",
                    color: "#6B7280",
                  }}
                >
                  {item.description}
                </Typography>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default GroomingServices;
