import React from "react";
import { Box } from "@mui/material";
import CreateProfile from "../components/create-profiles/CreateProfile";

const SignUp = () => {
  return (
    <Box sx={{ marginTop: "20px" }}>
       <CreateProfile/>
    </Box>
  );
};

export default SignUp;
