import { AppHeader } from "./app-header/app-header";
import "./layout.scss";
import { AppFooter } from "./app-footer/app-footer";
import { Outlet, useLocation } from "react-router-dom";
export const Layout = ({ userId }) => {
  const location = useLocation();
  const isUnderConstruction = location.pathname === "/under-construction";
  return (
    <>
      <div className="smd-sales-body">
        {!isUnderConstruction && (
          <div className="smd-sales-layoudt">
            <AppHeader />
          </div>
        )}
        <div className="smd-content">
          <Outlet />
        </div>
        {!isUnderConstruction && <AppFooter />}
      </div>
    </>
  );
};
