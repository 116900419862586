import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { BASE_API } from "../config";

const Login = ({ setUserData }) => {
  const navigate = useNavigate();
  const [isErrorMsg, setIsErrorMsg] = useState("");
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const businessName = "Pet Help Squad";

  const onSubmit = async (formValues) => {
    formValues.business_name = businessName;
    formValues.companyShortCode = "PHS";
    try {
      const response = await fetch(`${BASE_API}auth/signin`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify(formValues),
      });
      const responseData = await response.json();
      setUserData(responseData?.data?.user);
      if (responseData.data?.user !== null) {
        toast.success(responseData?.message ?? `SignIn Successfully!`);
        navigate(`/status`);
        reset();
      }
    } catch (error) {
      const errMsg = error?.message;
      toast.error(errMsg);
    }

    //   if (response.ok) {
    //     const responseData = await response.json();
    //     const token = responseData.data.token || null;
    //     localStorage.setItem("loginToken", token);

    //     if (responseData.status) {
    //       // router.replace("/under-construction");
    //       navigate("/status");
    //     } else {
    //       setIsErrorMsg(responseData.msg);
    //     }
    //   } else if (response.status === 452 || 453) {
    //     const responseData = await response.json();
    //     setIsErrorMsg(responseData.msg);
    //   }
    // } catch (error) {
    //   setIsErrorMsg("Something went wrong. Please try again!");
    // }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsErrorMsg("");
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isErrorMsg]);

  return (
    <Grid container sx={{ marginTop: "100px" }}>
      <Grid item xs={12} md={5} sx={{ margin: "0 auto" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={4}
            sx={{
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              padding: "50px 50px",
              borderRadius: "20px",
            }}
          >
            <Grid xs={12} md={12} sx={{ textAlign: "center" }}>
              <Typography
                className="primary-color"
                sx={{ fontSize: "30px", fontWeight: "600" }}
              >
                Login
              </Typography>
              {isErrorMsg && (
                <p
                  style={{ color: "red", fontSize: "20px", fontWeight: "600" }}
                >
                  {isErrorMsg}
                </p>
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                className="input-yourname"
                id="email"
                label="Email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                className="input-yourname"
                id="password"
                label="Password"
                type="password"
                {...register("password", { required: true })}
              />
              {errors.password && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                disableElevation
                sx={{ bgcolor: "#000958 !important", width: "100%" }}
                variant="contained"
              >
                Login
              </Button>

              <Typography textAlign="center" className="font-weight-600">
                Do not have an account ?
                <Button
                  href="/register"
                  variant="text"
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "600",
                    color: "#000958",
                  }}
                >
                  SignUp
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;
