import { ReactComponent as Twitter } from "../../../assets/svg/footer/twitter-icon.svg";
import { ReactComponent as Dribble } from "../../../assets/svg/footer/dribble-icon.svg";
import { ReactComponent as Insta } from "../../../assets/svg/footer/insta-icon.svg";
import { ReactComponent as Youtube } from "../../../assets/svg/footer/youtube-icon.svg";

export const servicesData = [
  {
    id: 0,
    title: "Grooming Services",
    path: "/services#grooming-services",
  },
  {
    id: 1,
    title: "Pet Sitter Services",
    path: "/services#pet-sitting-services",
  },
  {
    id: 2,
    title: "Veterinary Services",
    path: "/services#veterinary-services",
  },
];

export const Supporting = [
  {
    id: 0,
    path: "Pet boarding",
  },
  {
    id: 1,
    path: "Pet red book",
  },
  {
    id: 2,
    path: "Pet nutritionist",
  },
  {
    id: 3,
    path: "Jog the dog",
  },

  {
    id: 4,
    path: "Pet events",
  },
];
export const SiteLinks = [
  {
    id: 0,
    path: "/about",
    title: "About Us",
  },
  {
    id: 1,
    path: "/contact",
    title: "Contact Us",
  },
  {
    id: 2,
    path: "/career",
    title: "Careers",
  },
  {
    id: 3,
    path: "/terms-and-conditions",
    title: "Terms & Conditions",
  },
  {
    id: 4,
    title: "Privacy Policy",
    path: "/privacy-policy",
  },
];

export const helpCenterData = [
  {
    id: 0,
    path: "Gift Cards",
  },
  {
    id: 1,
    path: "FAQ",
  },
  {
    id: 2,
    path: "Chronicles",
  },
  {
    id: 3,
    path: "Our Collection",
  },
];
export const socialIcons = [
  {
    id: 0,
    icon: <Twitter />,
  },
  {
    id: 1,
    icon: <Dribble />,
  },
  {
    id: 2,
    icon: <Insta />,
  },
  {
    id: 3,
    icon: <Youtube />,
  },
];
