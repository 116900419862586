import "./App.scss";
import "./index.scss";
import { Layout } from "./layout/layout";
import { HomePage } from "./pages/home-page";
import { ServicePage } from "./pages/services-page";
import Contact from "./pages/contact-page";
import ImgMediaCard from "./pages/pricing-page";
import About from "./pages/about-page";
import Career from "./pages/career-page";
import SignUp from "./pages/sign-up";
import Login from "./pages/login-page";
import UnderConstruction from "./pages/under-construction-page";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicyPage from "./pages/privacy-policy-page";
import TermsAndConditionsPage from "./pages/terms-and-conditions-page";
import { Toaster as HotToaster } from "react-hot-toast";
import { VerificationTimer } from "./pages/GetVerified";
import StatusPage from "./pages/StatusPage";
import { Suspense, useState } from "react";

function App() {
  const [userData, setUserData] = useState({});
  return (
    <div className="App">
      <Suspense fallback={<h1>Loading</h1>}>
        <Router>
          <Routes>
            <Route element={<Layout userId={userData?._id} />}>
              <Route path="/" exact Component={HomePage} />
              <Route path="about" exact Component={About}>
                {" "}
              </Route>
              <Route path="career" exact Component={Career} />
              <Route path="services" exact Component={ServicePage} />
              <Route path="contact" exact Component={Contact} />
              <Route path="pricing" exact Component={ImgMediaCard} />
              <Route
                path="privacy-policy"
                exact
                Component={PrivacyPolicyPage}
              />
              <Route
                path="terms-and-conditions"
                exact
                element={<TermsAndConditionsPage />}
              />
              <Route path="sign-up" exact Component={SignUp} />
              <Route
                path="login"
                exact
                element={<Login setUserData={setUserData} />}
              />
              <Route
                path="under-construction"
                exact
                Component={UnderConstruction}
              />

              <Route
                path="status"
                exact
                element={<StatusPage userData={userData} />}
              />

              {/*
          <Route path="me" Component={<OwnUserProfile />} />
          <Route path=":id" Component={<UserProfile />} /> */}
            </Route>
            <Route
              path="verification-timer"
              exact
              Component={VerificationTimer}
            />
          </Routes>
        </Router>
        <HotToaster position="top-center" reverseOrder={false} />
      </Suspense>
    </div>
  );
}

export default App;
