import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./styles/common.scss";
import "./styles/utilities.scss";
import "./styles/screen-media-query.scss";
import App from "./App";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SnackbarProvider
    maxSnack={3}
    preventDuplicate
    anchorOrigin={{
      horizontal: "center",
      vertical: "top",
    }}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </SnackbarProvider>
);
