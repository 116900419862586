// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-behavior: smooth;
}

.main-pricing-pag {
  display: "flex";
  align-items: center;
  justify-content: center;
  width: "100%";
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AACA;EACE,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AAEF","sourcesContent":["html {\n  scroll-behavior: smooth;\n}\n.main-pricing-pag {\n  display: \"flex\";\n  align-items: center;\n  justify-content: center;\n  width: \"100%\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
