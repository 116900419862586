import React from "react";
import TermsAndConditions from "../components/terms-and-conditions";

export default function TermsAndConditionsPage() {
  return (
    <React.Fragment>
      <TermsAndConditions />
    </React.Fragment>
  );
}
