import React from "react";
import { Container, Typography } from "@mui/material";
import Careermain from "../components/Career/Careermain";
import JoinUs from "../components/Career/JoinUS";
function Career() {
  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: "90%",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // background: "white",
        flexDirection: "column",
      }}
    >
           <Typography
        style={{
          fontSize: "40px",
          fontFamily: "Roboto",
          lineHeight: "50px",
          fontWeight: 600,
          color: "#000958",
          marginTop: "60px",
        }}
      >
        Careers
      </Typography>
      {/* <Container
        maxWidth={false}
        sx={{
          maxWidth: "95%",

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "white",
          flexDirection: "column",
        }}
      > */}
        <Careermain />
        <JoinUs />
      {/* </Container> */}
    </Container>
  );
}

export default Career;
