import * as React from "react";
import { Typography, Container } from "@mui/material";
import "../index.scss";
import PricingCards from "../components/pricingCards";
export default function Pricing() {
  return (
    <Container
      maxWidth={false}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "100px",
        flexDirection: "column",
        width: "100%",
        maxWidth: "95%",
      }}
    >
      <Typography
        sx={{
          color: "#000958",
          marginBottom: "100px",
          fontSize: "40px",

          fontFamily: "Roboto",
          fontWeight: 600,
          lineHeight: "50px",
          marginLeft: "10px",
        }}
      >
        Pricing
      </Typography>
      <PricingCards />
    </Container>
  );
}
