import React from "react";
import { Typography, Container } from "@mui/material";
import GetInTouch from "../components/GetInTouch";
import RequestACall from "../components/RequestACall";
function Contact() {
  return (
    <div className="service-main-div">
      <Container
        maxWidth={false}
        sx={{
          maxWidth: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            fontSize: "40px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            marginTop: "100px",
            fontWeight: 600,
            color: "#000958",
          }}
        >
          Contact Us
        </Typography>
        <GetInTouch />
        <RequestACall />
      </Container>
    </div>
  );
}

export default Contact;
