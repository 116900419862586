import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

function PetProducts() {
  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50px",
      }}
      id="pet-products"
    >
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <img
            src="../service-page/pet-products.svg"
            className="services-image"
            alt="pet products image"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontSize: "40px",
              fontFamily: "Roboto",
              lineHeight: "50px",
              fontWeight: 600,
              color: "#000958",
              marginTop: "60px",
            }}
          >
            Pet Products
          </Typography>
          <Box className="grooming-para">
            Our platform also features a marketplace where you can purchase a
            wide variety of pet products from trusted sellers. Whether you need
            food, toys, grooming supplies, or accessories, we’ve got you
            covered. Browse our selection and find everything you need to keep
            your pet happy and healthy.
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PetProducts;
