import React from "react";
import "./index.scss";
import { Box, Grid, Typography } from "@mui/material";
import backgroundImage from "../assets/svg/backgroundimage-1.svg";

function CustomerTrust() {
  return (
    <Grid
      container
      spacing={5}
      sx={{
        borderRadius: "32px",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "100%",
        marginY: 20,
      }}
    >
      <Grid item xs={12} md={12} lg={6}>
        <Typography
          className="margin-top-2"
          style={{
            fontSize: "40px",
            fontFamily: "Roboto",
            fontWeight: 700,
            color: "#000958",
          }}
        >
          Why Customer Trust Us
        </Typography>
        <Typography variant="p" className="what-wedo-para">
          Customers trust Pets Help Squad because the platform is 100% compliant
          with the guidelines set by RSPCA and the Animal Welfare Act,
          demonstrating a commitment to animal welfare. Additionally, Pets Help
          Squad provides a range of services related to pets, making it a
          one-stop-shop for all pet-related needs. The platform also carefully
          matches pets with loving homes and reliable care providers, ensuring
          that pets receive the best possible care even when their owners are
          away.
        </Typography>
        <Box sx={{ marginTop: 12 }}>
          <img src="../assets/homepageImages/rspca2.svg" alt="rspca" />
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Box
          sx={{
            textAlign: "right",
            marginTop: {
              xs: "-10px",
              sm: "-30px",
              md: "-70px",
              lg: "-130px",
              xl: "-150px",
            },
          }}
        >
          <img
            src="../assets/homepageImages/whatwedoimage.png"
            alt="petdog"
            style={{ width: "100%", maxWidth: "fit-content" }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default CustomerTrust;
