import React, { useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Customer from "./sections/customer/Customer";
import Groomer from "./sections/groomer/Groomer";
import PetSitter from "./sections/pet-sitter/PetSitter";
import signUpBackImg from "../../assets/svg/signup-back-img.svg";

function CreateProfile() {
  const [value, setValue] = useState("0");

  // const signUpUrl =
  //   "https://9b92-2407-d000-d-812d-b8c7-d71c-c7e7-facb.ngrok-free.app";
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginY: 15,
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6} lg={4}>
          <Typography
            sx={{
              color: "#000958",
              fontSize: "50px",
              fontWeight: "600",
              textAlign: { xs: "center", md: "right" },
              marginBottom: 2,
            }}
          >
            SignUp Here
          </Typography>
        </Grid>
        <Grid item md={6} lg={6} sx={{ display: { xs: "none", md: "block" } }}>
          {/* <img
            src="../assets/homepageImages/register-icon.svg"
            alt="signup-img"
          /> */}
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={5}
        columnSpacing={3}
        sx={{
          marginTop: 0,
          borderRadius: "32px",
          backgroundImage: `url(${signUpBackImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid item xs={12} md={6} alignSelf={"center"}>
          <Box>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    className="text-transform primary-color sub-heading font-weight-700"
                    label="Customer"
                    value="0"
                  />
                  <Tab
                    className="text-transform primary-color sub-heading font-weight-700"
                    label="Groomer"
                    value="1"
                  />
                  <Tab
                    className="text-transform primary-color sub-heading font-weight-700"
                    label="Pet-Sitter"
                    value="2"
                  />
                </TabList>
              </Box>
              <TabPanel value="0">
                <Customer />
              </TabPanel>
              <TabPanel value="1">
                <Groomer />
              </TabPanel>
              <TabPanel value="2">
                <PetSitter />
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              marginTop: {
                xs: "-20px",
                sm: "-40px",
                md: "-120px",
                lg: "-200px",
                xl: "-233px",
              },
            }}
          >
            <img
              src="../assets/homepageImages/signUp-img.svg"
              alt="signUp-img"
              width="100%"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CreateProfile;
