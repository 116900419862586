import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField, Button, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BASE_API_ENQUIRY, BASE_API_ENQUIRY_API_KEY } from "../config";
import { enqueueSnackbar } from "notistack";

const contactUsData = [
  {
    name: "name",
    label: "Name",
    type: "text",
    md: 6,
    required: true,
    pattern: {value: /^[A-Za-z]+$/,message: "only alphabets are allowed"},
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    md: 6,
    required: true,
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    type: "text",
    md: 6,
    required: true,
    pattern: {value: /^\+44\d{10}$/,message: "Contact number must start with +44 and be followed by exactly 10 digits!"},
  },
  {
    name: "query",
    label: "Message",
    multiline: true,
    rows: 5,
    type: "text",
    md: 12,
    required: true,
  },
];

function RequestACall() {
  const [isErrorMsg, setIsErrorMsg] = useState("");
  const [isSuccessMsg, setIsSuccessMsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset, // Add form reset function from react-hook-form
  } = useForm();
  const onSubmit = async (formValues) => {
    console.log(formValues);
    try {
      const response = await axios?.post(
        `${BASE_API_ENQUIRY}/enquiries`,
        formValues,
        {
          headers: {
            "x-api-key": BASE_API_ENQUIRY_API_KEY,
          },
        }
      );
      enqueueSnackbar(response?.data?.message, {
        variant: "success",
      });
      reset?.();
    } catch (error) {
      enqueueSnackbar(error?.data?.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsSuccessMsg("");
      setIsErrorMsg("");
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isSuccessMsg, isErrorMsg]);
  console.log(errors);

  return (
    <>
      <div className="contact-us">
        <img src="../contact-page/Rectangle.svg" alt="pet-dog" />
        <Typography
          sx={{
            color: "#37393F",
            fontSize: "18px",
            fontFamily: "Roboto",
            fontWeight: 500,
            lineHeight: "22px",
            marginLeft: "10px",
          }}
        >
          Contact Us
        </Typography>
      </div>
      <Typography
        style={{
          fontSize: "40px",
          fontFamily: "Roboto",
          lineHeight: "50px",
          fontWeight: 600,
          color: "#000958",
          marginTop: "10px",
        }}
      >
        Request A Call Back!
      </Typography>
      {isErrorMsg && (
        <p style={{ color: "red", fontSize: "20PX", fontWeight: "600" }}>
          {isErrorMsg}
        </p>
      )}
      {isSuccessMsg && (
        <p style={{ color: "green", fontSize: "20PX", fontWeight: "600" }}>
          {isSuccessMsg}
        </p>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%", marginTop: "40px" }}
      >
        <Grid container spacing={{ md: 2, sm: 0 }} marginTop={5}>
          {contactUsData.map((field) => (
            <Grid item xs={12} sm={12} md={field.md}>
              <TextField
                disabled={field?.disabled}
                className="input-yourname"
                type={field?.type}
                id={field.name}
                label={field.label}
                defaultValue={field.defaultValue || ""}
                multiline={field.multiline}
                rows={field.multiline ? field.rows : ""}
                {...register(field.name, {
                  required:{
                    value: field?.required,
                    message: "This field is required",
                  },
                  pattern: field?.pattern
                    ? {
                        value: field.pattern.value,
                        message: field.pattern.message,
                      }
                    : null,
                })}
                fullWidth
                // required
              />
              {errors[field.name] && (
                <span style={{ color: "red" }}>{errors[field.name].message}</span>
              )}{" "}
            </Grid>
          ))}
          <Box sx={{ textAlign: "center", width: "100%" }}>
            <Button
              disableElevation
              sx={{ p: "11px 40px", bgcolor: "#000958 !important" }}
              className="text-transform margin-left-0"
              variant="contained"
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </form>
      {/* 
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
        }}
        container
        xs={12}
        spacing={4}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="contact-us">
            <img src="../contact-page/Rectangle.svg" alt="pet-dog" />
            <Typography
              sx={{
                color: "#37393F",
                fontSize: "18px",
                fontFamily: "Roboto",
                fontWeight: 500,
                lineHeight: "22px",
                marginLeft: "10px",
              }}
            >
              Contact Us
            </Typography>
          </div>
          <Typography
            style={{
              fontSize: "40px",
              fontFamily: "Roboto",
              lineHeight: "50px",
              fontWeight: 600,
              color: "#000958",
              marginTop: "10px",
            }}
          >
            Request A Call Back!
          </Typography>
          {isErrorMsg && (
            <p style={{ color: "red", fontSize: "20PX", fontWeight: "600" }}>
              {isErrorMsg}
            </p>
          )}
          {isSuccessMsg && (
            <p style={{ color: "green", fontSize: "20PX", fontWeight: "600" }}>
              {isSuccessMsg}
            </p>
          )}
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container xs={12} spacing={2}>
            {contactUsData.map((field) => (
              <Grid
                item
                key={field.name}
                // xl={field.multiline ? 12 : 6}
                xs={12}
                md={field.md ? field.md : 12}
                // lg={field.multiline ? 12 : 6}
                sm={12}
                className="margin-top-3"
              >
                <TextField
                  disabled={field?.disabled}
                  className="input-yourname"
                  type={field?.type}
                  id={field.name}
                  label={field.label}
                  defaultValue={field.defaultValue || ""}
                  multiline={field.multiline ? field.rows : ""}
                  {...register(field.name, { required: field?.required })}
                />
                {errors[field.name] && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </Grid>
            ))}
            <Grid
              item
              xl={12}
              xs={12}
              md={12}
              lg={12}
              sm={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                disableElevation
                sx={{ p: "11px 40px", bgcolor: "#000958 !important" }}
                className="text-transform margin-left-0"
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid> */}
    </>
  );
}

export default RequestACall;
