import React from "react";
import { Grid, Typography } from "@mui/material";

function ConnectingPets() {
  return (
    <div>
      <Grid sx={{ display: "flex", alignItems: "center" }} container xs={12}>
        <Grid
          item
          lg={6}
          md={12}
          xs={12}
          xl={6}
          sm={12}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="connecting-pets-section">
            <div className="text-icon">
              <Typography
                style={{
                  fontSize: "72px",
                  fontWeight:600,
                  fontFamily: "Roboto",
                  lineHeight: "80px",
                  color: "#000958",
                }}
              >
                Connecting <span className="pets">Pets</span> With Loving Homes
                And Reliable Care.
                <img
                  src="../assets/homepageImages/heroIcon.svg"
                  className="icon-home"
                  height={"60px"}
                  alt="icon"
                />
              </Typography>
            </div>

            <div className="pets-owner">
              <img
                src="../assets/homepageImages/customer.png"
                alt="oweners"
              ></img>
              <Typography
                style={{
                  fontSize: "20px",
                  fontFamily: "Roboto",
                  lineHeight: "30px",
                  fontWeight: 600,
                  color: "#000958",
                  marginRight: "30px",
                }}
              >
                Our happy pets Owners
              </Typography>
            </div>
            <div className="reviews">
              <img
                src="../assets/homepageImages/Vector.png"
                alt="oweners"
              ></img>
              <Typography
                style={{
                  fontSize: "20px",
                  fontFamily: "Roboto",
                  lineHeight: "20px",
                  fontWeight: 500,
                  color: "#000958",
                  marginLeft: "10px",
                }}
              >
                4.8
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  fontFamily: "Roboto",
                  lineHeight: "20px",
                  fontWeight: 500,
                  color: "#656689",
                  marginLeft: "10px",
                }}
              >
                (3.5k reviews)
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          xl={6}
          sm={8}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="mainiamge">
            <img
              src="../assets/homepageImages/maindogimage.svg"
              alt="oweners"
              className="about-main-image"
            />
            {/* <div className="vaccinations-div">
              <img
                src="../icons/injection-icon.png"
                alt="oweners"
                className="vacination-pet-icon"
              ></img>
              <Typography
                className="vacination-pet-icon-text"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "20px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                Vaccinations
              </Typography>
            </div> */}
            {/* <div className="petstraining-div">
              <img
                src="../icons/pet-icon.png"
                alt="oweners"
                className="vacination-pet-icon"
              ></img>
              <Typography
                className="vacination-pet-icon-text"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "20px",
                }}
              >
                Pets Training
              </Typography>
            </div> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ConnectingPets;
