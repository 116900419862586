import {
  Box,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import nutritionist from "../../assets/svg/service-icons/nutritionist.svg";
import petGym from "../../assets/svg/service-icons/petGym.svg";
import microChiping from "../../assets/svg/service-icons/microChiping.svg";
import petDietitian from "../../assets/svg/service-icons/petDietitian.svg";
import petTraining from "../../assets/svg/service-icons/petTraining.svg";
import vaccination from "../../assets/svg/service-icons/vaccination.svg";
import videoCnsultation from "../../assets/svg/service-icons/videoCnsultation.svg";

const veterinaryData = [
  {
    icon: nutritionist,
    title: "Pet Nutritionist",
    description: "Expert advice on your pet’s dietary needs and nutrition.",
  },
  {
    icon: petGym,
    title: "Pet Gym",
    description: "Exercise programmes to keep your pet fit and active.",
  },
  {
    icon: microChiping,
    title: "Pet Microchipping",
    description: "Microchipping services for identification and safety.",
  },
  {
    icon: petDietitian,
    title: "Pet Dietitian",
    description:
      "Specialised diet plans tailored to your pet’s health requirements.",
  },
  {
    icon: petTraining,
    title: "Pet Training",
    description:
      "Professional training sessions to teach your pet obedience and good behaviour.",
  },
  {
    icon: vaccination,
    title: "Vaccination",
    description: "Essential vaccinations to protect your pet from diseases.",
  },
  {
    icon: videoCnsultation,
    title: "Video Consultation",
    description:
      "Convenient online consultations with our vet doctors for non-urgent health concern.",
  },
];

function VeterinaryServices() {
  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      id="veterinary-services"
    >
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontSize: "40px",
              fontFamily: "Roboto",
              lineHeight: "50px",
              fontWeight: 600,
              color: "#000958",
              marginTop: "60px",
            }}
          >
            Veterinary Services
          </Typography>
          <Box variant="" className="grooming-para">
            Our professional vet doctors offer a range of veterinary services to
            keep your pets healthy and happy. Depending on the nature of the
            service required, we offer home visits, clinic appointments, and
            video consultations:
          </Box>
        </Grid>
        <Grid item xs={12} md={6} textAlign="end">
          <img
            src="../service-page/veterinary-services.svg"
            className="services-image"
            alt="veterinary services image"
          />
        </Grid>
        {veterinaryData?.map((item) => (
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                height: "100%",
                minHeight: "280px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "30px",
                borderRadius: "20px",
                backgroundColor: "#fafafa",
              }}
            >
              <CardMedia
                component="img"
                image={item?.icon}
                alt="bathing"
                sx={{
                  width: "100px",
                  height: "100px",
                }}
              />
              <CardContent sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    fontSize: "25px",
                    fontFamily: "Roboto",
                    lineHeight: "24px",
                    fontWeight: 500,
                    marginTop: "20px",
                    marginBottom: "20px",
                    color: "#000958",
                  }}
                >
                  {item?.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    lineHeight: "28px",
                    fontWeight: 400,
                    marginTop: "20px",
                    marginBottom: "20px",
                    color: "#6B7280",
                  }}
                >
                  {item.description}
                </Typography>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default VeterinaryServices;
