import React from "react";
import { Box, Typography } from "@mui/material";
import GroomingServices from "../components/services/grooming-services";
import PetSittingServices from "../components/services/pet-sitting-services";
import VeterinaryServices from "../components/services/veterinary-services";
import PetProducts from "../components/services/pet-products";

export const ServicePage = () => {
  return (
    <div className="service-main-div">
      <Typography
        style={{
          fontSize: "40px",
          fontFamily: "Roboto",
          lineHeight: "50px",
          fontWeight: 600,
          color: "#000958",
          marginTop: "60px",
        }}
      >
        Services
      </Typography>
      <Box className="services-para">
        Welcome to Pets Help Squad! We offer a comprehensive range of services
        to cater to all your pet care needs. Our dedicated team of professionals
        is here to provide top-quality care and support for your beloved pets.
        Explore our services below:
      </Box>
      <GroomingServices />
      <PetSittingServices />
      <VeterinaryServices />
      <PetProducts />
    </div>
  );
};
