import React from "react";
// import { Typography } from "@mui/material";
function JoinUs() {
  return (
    <>
      {/* <Typography
        style={{
          fontSize: "18px",
          fontFamily: "Roboto",
          lineHeight: "22px",
          color: "#F2514F",
          fontWeight: 500,
          marginTop: "20px",
        }}
      >
        Come join us
      </Typography>
      <Typography
        style={{
          fontSize: "36px",
          fontFamily: "Roboto",
          lineHeight: "44px",
          color: "#000958",
          fontWeight: 700,
          marginTop: "20px",
        }}
      >
        Career Openings
      </Typography>
      <p
        style={{
          marginBottom: "20px",
          fontSize: "14px",
          fontFamily: "Roboto",
          lineHeight: "24px",
          textAlign: "center",
          color: "#111827",
          fontWeight: 400,
          maxWidth: "674px",
        }}
      >
        We’re always looking for creative, talented self-starters to join the{" "}
        <span style={{ color: "#000958", fontWeight: 600 }}>
          Pets Help Saqad
        </span>{" "}
        family. Check out our open roles below and fill out an application.
      </p> */}
      <iframe
        src="https://recruiting-stg.personnellibrary.co.uk//job-boards?boardName=petsBoard-1716270804858"
        style={{
          width: "100%",
          height: "100%",
          allowfullscreen: false,
          border: "none",
          marginBottom: "60px",
          textAlign: "center",
          minHeight: "950px",
          marginTop: "60px",
        }}
        title="Jobs Widget"
        scrolling="no"
      ></iframe>
    </>
  );
}

export default JoinUs;
