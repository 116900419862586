import React from "react";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const cardData = [
  {
    id: 1,

    title: "Have any question?",

    image: "../contact-page/call.svg",

    info: "0044330 043 1210",
  },
  {
    id: 2,

    title: "Write email",

    image: "../contact-page/msg.svg",
    info: "info@petshelpsquad.co.uk",
  },
  {
    id: 3,

    title: "Visit anytime",
    image: "../contact-page/locations.svg",

    info: "36-38 Cornhill, London, England, EC3V 3NG, United Kingdom location",
    location:
      "https://www.google.com/maps/search/Unit+8,+Northgate+Industrial+Park,+Collier+Row+Road,+Romford,+England,+RM5+2BG/@51.5933085,0.1421018,17z?entry=ttu",
  },
];

function GetInTouch() {
  return (
    <Grid container spacing={5} marginY={10}>
      <Grid item xl={6} xs={12} md={12} lg={6} sm={12} textAlign="center">
        <img
          src="../contact-page/mainimage.svg"
          alt="petdog"
          className="contact-image"
        />
      </Grid>
      <Grid
        item
        xl={6}
        xs={12}
        md={12}
        lg={6}
        sm={12}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="lets-talk">
          <img src="../contact-page/Rectangle.svg" alt="petdog"></img>
          <Typography
            style={{
              fontSize: "18px",
              fontFamily: "Roboto",
              lineHeight: "22px",
              fontWeight: 500,
              color: "#37393F",
              marginLeft: "10px",
            }}
          >
            Let’s Talk
          </Typography>
        </div>
        <Typography
          style={{
            fontSize: "40px",
            fontFamily: "Roboto",
            lineHeight: "50px",
            fontWeight: 600,
            color: "#000958",
            marginTop: "10px",
          }}
        >
          Get in touch with us
        </Typography>
        <p className="contact-us-para">
          Need assistance or have questions? Our dedicated support team is here
          to help. Reach out to us anytime, and we'll get back to you promptly!
        </p>
        {cardData.map((data, index) => (
          <Grid item xl={12} xs={12} md={12} lg={12}>
            <div className="main-info">
              <img src={data.image} alt={data.title} />
              <div className="main-info-text">
                <Typography
                  sx={{
                    color: "#656689",

                    fontSize: "16px",

                    fontFamily: "Robto",
                    fontWeight: 400,
                    lineHeight: "22px",
                  }}
                >
                  {data.title}
                </Typography>
                <Link to={data.location}>
                  <Typography
                    variant="h3"
                    sx={{
                      color: " #000958",

                      fontSize: "18px",
                      maxWidth: "380px",
                      fontFamily: "Robto",
                      fontWeight: 500,
                      lineHeight: "22px",
                    }}
                  >
                    {data.info}
                  </Typography>
                </Link>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <Grid></Grid>
    </Grid>
  );
}

export default GetInTouch;
