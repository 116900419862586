import { AppBar, Box, Button, Container, Grid, Toolbar } from "@mui/material";
// import { ReactComponent as SMDLogo } from "../../assets/svg/logo/smd-logo.svg";
// import { ReactComponent as Profile } from "../../assets/svg/profile.svg";
// import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";
import { ReactComponent as Petlogo } from "../../assets/svg/logo/logo.svg";
import { LinkDropdown } from "./link-dropdown/link-dropdown";
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
const menu = [
  {
    name: "Home",
    onClick: () => {},
    href: "/",
  },
  {
    name: "About Us",
    onClick: () => {},
    href: "about",
  },
  {
    name: "Services",
    onClick: () => {},
    href: "services",
  },
  // {
  //   name: "Pricing",
  //   onClick: () => {},
  //   href: "pricing",
  // },
  {
    name: "Careers",
    onClick: () => {},
    href: "career",
  },
  {
    name: "Contact Us",
    onClick: () => {},
    href: "contact",
  },
];

export const AppHeader = () => {
  const token =
    typeof localStorage !== "undefined"
      ? localStorage.getItem("loginToken") || null
      : null;
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: {
            xs: "block",
            sm: "block",
            lg: "none",
            xl: "none",
            md: "block",
          },
        }}
      >
        <header className="header2">
          <img src="../assets/homepageImages/logo.svg" alt="logo" />
          <nav ref={navRef}>
            <Link to="/" onClick={showNavbar}>
              Home
            </Link>
            <Link to="about" onClick={showNavbar}>
              About Us
            </Link>
            <Link to="services" onClick={showNavbar}>
              Services
            </Link>
            <Link to="career" onClick={showNavbar}>
              Careers
            </Link>

            {/* <Link to="pricing" onClick={showNavbar}>
              Pricing
            </Link> */}
            <Link to="contact" onClick={showNavbar}>
              Contact Us
            </Link>
            <button className="nav-btn nav-close-btn" onClick={showNavbar}>
              <FaTimes />
            </button>
          </nav>
          <button className="nav-btn" onClick={showNavbar}>
            <FaBars />
          </button>
        </header>
      </Box>
      <Container
        maxWidth={false}
        sx={{
          flexGrow: 1,
          maxWidth: "98%",
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <AppBar
          position="relative"
          elevation={0}
          sx={{ bgcolor: "transparent" }}
        >
          <Toolbar>
            <Grid container>
              <Grid
                item
                xs={3}
                className="links flex align-center justify-space-between"
              >
                <Petlogo />
              </Grid>
              <Grid
                className="links flex align-center justify-space-between"
                item
                xs={6}
              >
                {menu.map((item, index) => (
                  <LinkDropdown key={index} menu={item} />
                ))}
              </Grid>
              <Grid item className="links flex align-center justify-end" xs={3}>
                <Link to={token ? "/under-construction" : "login"}>
                  <Button
                    disableElevation
                    sx={{ p: "11px 24px", bgcolor: "#000958 !important" }}
                    className="text-transform margin-left-0"
                    variant="contained"
                  >
                    Login
                  </Button>
                </Link>
                <Link to={token ? "/under-construction" : "sign-up"}>
                  <Button
                    disableElevation
                    sx={{ p: "11px 24px", bgcolor: "#000958 !important" }}
                    className="text-transform margin-left-0"
                    variant="contained"
                  >
                    Sign Up
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Container>
    </>
  );
};
